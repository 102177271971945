var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    {
      staticClass: "pa-1 mt-1",
      attrs: { outlined: "", color: "#e8eaf6" },
      on: {
        click: function ($event) {
          _vm.isList ? _vm.navigateOrder(_vm.model.id) : false
        },
      },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pa-1 pt-0 pb-0" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { staticClass: "mt-4 pa-0 pl-1", attrs: { cols: "2" } },
                [
                  _c("span", { staticClass: "body-2" }, [
                    _vm._v(_vm._s(_vm.model.number)),
                  ]),
                ]
              ),
              _c(
                "v-col",
                { staticClass: "pa-0 pl-5", attrs: { cols: "8" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "12" } },
                        [_vm._v(" " + _vm._s(_vm.model.customername) + " ")]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "6" } },
                        [
                          _vm._v(
                            " O: " +
                              _vm._s(
                                _vm._f("dateFormat")(_vm.model.orderdate)
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "v-col",
                        { staticClass: "pa-0", attrs: { cols: "6" } },
                        [
                          _vm._v(
                            " D: " +
                              _vm._s(
                                _vm._f("dateFormat")(_vm.model.deliverydate)
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "mt-4 pa-0 pr-2 text-right",
                  attrs: { cols: "2" },
                },
                [
                  _vm.isList
                    ? _c(
                        "v-btn",
                        { attrs: { icon: "" } },
                        [_c("v-icon", [_vm._v("mdi-information-outline")])],
                        1
                      )
                    : _c("h4", [
                        _vm._v(
                          " -" +
                            _vm._s(
                              _vm._f("absoluteNumber")(_vm.model.pickedqty)
                            ) +
                            " "
                        ),
                      ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }